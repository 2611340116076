






import { Component } from 'vue-property-decorator'

import { AbstractMegaMenuAddon } from './abstract'
import NavigationCTABoxUi from '../../../../../modules/NavigationCTABox/ui/NavigationCTABox.ui.vue'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<NavigationCTABoxAddon>({
  name: 'NavigationCTABoxAddon',
  components: { NavigationCTABoxUi }
})
export class NavigationCTABoxAddon extends AbstractMegaMenuAddon {}

export default NavigationCTABoxAddon
